import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';

class NavLink extends React.Component {
    static component_name = 'NavLink';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} />,
                <TextInput label={'url'} name={'href'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
                <CSSInput label={'active css'} name={'active_style'} default={{}} />,
            ],
            can_have_children: true,
        }
    }

    render() {
        var active = '';
        var path_split = window.location.pathname.split("/");
        var style = this.props.style || {};
        if ((this.props.href == '/' && path_split[1] == '') || (this.props.href == '/' + path_split[1] + '/')) {
            for (var index in this.props.active_style) {
                try {
                    style[index] = this.props.active_style[index];
                }
                catch {
                    console.log("??")
                }
            }
        }

        return (
            <a style={style} className={"nav-link" + active} href={this.props.href}>{this.props.name}</a>
        )
    }
}

export default NavLink;
