import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, TextArea, CSSInput, ListWithChildren, Image, Link} from 'library';
import Moment from 'react-moment';

class MultiImageViewer extends React.Component {
    static component_name = 'MultiImageViewer';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'class'} name={'className'} />,
                <TextArea label={'dataList'} name={'dataList'} />,
                <TextInput label={'object name'} name={'objectName'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
        }

        this.state = {};

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount(){
        if (this.props.dataList){
            try {
                var name = this.props.objectName;
                var dataList = JSON.parse(this.props.dataList);
                dataList.sort(function(a, b) {
                    return parseInt(a[name].order) - parseInt(b[name].order);
                });

                this.setState({
                    main_image: dataList[0][name]['url'],
                    images: dataList
                });
            }
            catch (e){
                dataList = [];
            }
        }
    }

    onClick(e) {
        this.setState({
            main_image: e.currentTarget.getAttribute("target")
        });
    }

    render() {
        var style = {};
        if (this.props.style){
            style = Object.assign({}, style, this.props.style);
        }

        var main_image = <Image src={this.state.main_image} style={{width:'100%'}} />;
        var images = JSON.stringify(this.state.images);

        var images = <ListWithChildren dataList={images} objectName={this.props.objectName} >
            <Link onClick={this.onClick} style={{display:'inline-block'}} target='{props.url}' ><Image src='{props.url}' style={{width:'100%'}} /></Link>
        </ListWithChildren>;

        return (
            <div style={style} className={this.props.className + ' row'} >
                <div className={'col-10'}>{main_image}</div>
                <div className={'col-2'}>{images}</div>
            </div>
        );
    }
}

export default MultiImageViewer;
