import React, {Component} from 'react';
import { Line } from 'react-chartjs-2';
import {TextInput, Json_Input} from 'library';
import {resolveVariables} from 'functions';

export default class LineChart extends Component {
    static component_name = 'LineChart';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} />,
                <TextInput label={'object name'} name={'object_name'} />,
                <TextInput label={'x axis prop name'} name={'x_prop_name'} />,
                <TextInput label={'y axis prop name'} name={'y_prop_name'} />,
                <Json_Input label={'data'} name={'data'} />,

            ],
            can_have_children: false,
        }
    }

    componentDidMount() {
      const { datasets } = this.refs.chart.chartInstance.data
    }

  render() {
      var labels = [];
      var data_items = [];
      var name = this.props.name || "";

      var data_list = resolveVariables({'data':this.props.data}, window.cmState.getGlobalState(this))['data']
      console.log("Line Chart Data List", data_list)

      for (var index in data_list) {
          try {
              console.log("Datum", data_list[index], data_list[index][this.props.object_name])
              var datum = data_list[index][this.props.object_name];
              data_items.push(datum[this.props.y_prop_name])
              labels.push(datum[this.props.x_prop_name])
          }
          catch (e) {
              console.log("Line Chart Error:", e)
          }
      }



      var data = {
        labels: labels,
        datasets: [
          {
            label: name,
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data_items
          }
        ]
      };

      console.log("Line Chart Complete", data)

    return (
      <div>
        <h2>{this.props.name}</h2>
        <Line ref="chart" data={data} />
      </div>
    );
  }


}
