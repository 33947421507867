import React, { Component } from 'react';
import {resolveVariables, ajaxWrapper, resolve_all_children, sort_objects} from 'functions';
import {Wrapper, NumberInput, TextInput, TextArea, CSSInput,
    Json_Input, Button, Select, Link, Image} from 'library';
import {CSVLink} from 'react-csv';

class ListWithChildren extends Component {
    static component_name = 'ListWithChildren';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'class'} name={'className'} />,
                <TextArea label={'dataList'} name={'dataList'} />,
                <TextInput label={'dataUrl'} name={'dataUrl'} />,
                <TextInput label={'object name'} name={'objectName'} />,
                <Json_Input label={'dataMapping'} name={'dataMapping'} />,
                <Json_Input label={'filters'} name={'filters'} />,
                <TextInput label={'noDataMessage'} name={'noDataMessage'} />,
                <TextArea label={'lastInstanceData'} name={'lastInstanceData'} />,
                <Select label={'Download Link'} name={'download_link'} options={[{'text':'True', value:true}, {'text':'False', value:false}]} defaultoption={false} />,
                <TextInput label={'Filename'} name={'filename'} default={'download'} />,
                <TextInput label={'Download Text'} name={'download_text'} default={'Download'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
                <Select label={'No Wrapper'} name={'nowrapper'} boolean={true} defaultoption={false} />,
            ],
            can_have_children: true,
        }

        var limit = (this.props && this.props.limit) || 20;
        this.state = {
            componentData: [],
            loaded:false,
            limit:limit,
            offset:0,
            download_data: [],
            currently_refreshing_data: false,
            pending_update_url: null
        };

        this.ajaxCallback = this.ajaxCallback.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.download_data = this.download_data.bind(this);
        this.download_data_callback = this.download_data_callback.bind(this);
    }

    componentDidMount() {
        if (this.props.dataUrl) {
            //Subscribe to all variables in dataUrl
            window.cmState.subscribe(this, this.props.dataUrl);
        }

        this.refreshData();

        if (this.props.download_link) {
            this.download_data();
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot){
        if (this.props.dataUrl != prevProps.dataUrl){
            this.refreshData();
        }
    }

    refreshData() {
        var limit = this.state.limit;
        var offset = this.state.offset;

        if (this.props.dataUrl) {
            var global_state = window.cmState.getGlobalState();
            var dataUrl = resolveVariables({'dataUrl':this.props.dataUrl}, global_state)['dataUrl'];

            var filterString = '';
            var filters = resolveVariables(this.props.filters, global_state);
            if (filters) {
                for (var index in filters) {
                    var filter = filters[index];
                    if (filter.indexOf("undefined") == -1) {
                        filterString += '&' + index + '=' + filter;
                    }
                }
            }

            if (dataUrl.indexOf('?') == -1) {
                dataUrl += '?limit=' + limit + '&offset=' + offset + filterString;
            }
            else {
                if (dataUrl.indexOf('limit') == -1) {
                    dataUrl += '&limit=' + limit + '&offset=' + offset + filterString;
                }
            }

            if(!this.state.currently_refreshing_data){
                ajaxWrapper("GET", dataUrl, {}, this.ajaxCallback);
                this.setState({currently_refreshing_data: true});
            } else {
                // Save most recent blocked update for later
                this.setState({pending_update_url: dataUrl});
            }
        }
        else {
            this.setState({loaded: true});
        }
    }

    ajaxCallback(value) {
        console.log("List Callback", value);

        // If there is a saved update, prevent other behavior and make query
        if (this.state.pending_update_url){
            ajaxWrapper("GET", this.state.pending_update_url, {}, this.ajaxCallback);
            this.setState({pending_update_url: null});
        }
        else {
            if (this.props.dataMapping) {
                var componentData = [];
                for (var index in value) {
                    var data = {}
                    data[this.props.objectName] = resolveVariables(this.props.dataMapping, value[index][this.props.objectName])
                    componentData.push(data)
                }
            }
            else {
                var componentData = value;
            }

            this.setState({componentData:componentData, loaded:true, currently_refreshing_data: false});
        }
    }

    download_data() {
      if (this.props.dataUrl) {
        var dataUrl = resolveVariables({'dataUrl':this.props.dataUrl}, window.cmState.getGlobalState())['dataUrl'];

        var filterString = '';
        var filters = resolveVariables(this.props.filters, window.cmState.getGlobalState());
        if (filters) {
            for (var index in filters) {
                var filter = filters[index];
                if (filter.indexOf("undefined") == -1) {
                    filterString += '&' + index + '=' + filter;
                }
            }
        }

        ajaxWrapper("GET", dataUrl, {}, this.download_data_callback);
      }
    }

    download_data_callback(result) {
        var headers = [];
        if (result.length > 0) {
            var object = result[0][this.props.objectName];

            if (this.props.dataMapping) {
                for (var key in this.props.dataMapping) {
                    headers.push(key);
                }
            }
            else {
                for (var index in object) {
                    headers.push(index);
                }
            }
        }

        var data = [headers];
        if (this.props.dataMapping) {
            var componentData = [];
            for (var index in result) {
                var row = [];
                var object = resolveVariables(this.props.dataMapping, result[index][this.props.objectName]);
                for (var index in headers) {
                    row.push(object[headers[index]]);
                }

                data.push(row);
            }
        }
        else {
            for (var index in result) {
                var row = [];
                var object = result[index][this.props.objectName];
                for (var index in headers) {
                    row.push(object[headers[index]]);
                }

                data.push(row);
            }
        }

        console.log("Data", data);
        this.setState({download_data: data})

    }

    render() {
        var content = [];

        if (this.props.download_link) {
            content.push(<CSVLink data={this.state.download_data} filename={this.props.filename + ".csv"} style={{color:'blue', cursor:'pointer'}}>{this.props.download_text || "Download"}</CSVLink>)
        }

        var componentData = this.state.componentData;
        if (this.props.dataList) {
            try {
                var componentData = JSON.parse(this.props.dataList);
            }
            catch (e) {
                var componentData = this.props.dataList;
            }

            console.log("List Component data", componentData);

            if (Array.isArray(componentData)){
                if (this.props.dataMapping) {
                    for (var index in componentData) {
                        var data = {}
                        componentData[index][this.props.objectName] = resolveVariables(this.props.dataMapping, componentData[index][this.props.objectName]);
                    }
                }
            }
        }


        if (this.props.order_by) {
            componentData = sort_objects(componentData, this.props.order_by)
            console.log("Order By", this.props.order_by, componentData)
        }


        if (componentData.length > 0) {
            for (var i in componentData) {
                var table_row = []
                var data = componentData[i][this.props.objectName];

                if (data) {
                    data['refreshData'] = this.refreshData;
                    data['setGlobalState'] = this.props.setGlobalState;

                    var components = resolve_all_children(this, data);
                    var salty_components = [];
                    for (var i in components){
                        var component = components[i];
                        // Create a key using both the database ID and position in the component configuration
                        var key = data['id'] + '_' + i;
                        component = React.cloneElement(component, {key: key});
                        salty_components.push(component);
                    }
                    content.push(salty_components);
                }
            }
        }
        else if (this.props.lastInstanceData == undefined) {
            var noDataMessage = this.props.noDataMessage || 'No ' + this.props.objectName + 's Found';
            content.push(<p>{noDataMessage}</p>);
        }

        if (this.props.lastInstanceData) {
            var data = this.props.lastInstanceData;
            data['refreshData'] = this.refreshData;
            data['setGlobalState'] = this.props.setGlobalState;
            content.push(resolve_all_children(this, data));
        }

        if (!(this.props.nowrapper)){
            content.push(<div style={{height:'50px'}}></div>);

            if (this.state.offset > 0) {
                var prev = <Button type={'primary'} text={'Previous'} onClick={() => this.setState({offset:this.state.offset - this.state.limit}, this.refreshData)} />
                content.push(prev);
            }

            if (componentData.length == this.state.limit) {
                var next = <Button type={'primary'} text={'Next'} onClick={() => this.setState({offset:this.state.offset + this.state.limit}, this.refreshData)} />
                content.push(next);
            }
        }

        if (this.state.currently_refreshing_data){
            var refreshing_style = {
                padding:'30px 15px', textAlign:'center', position:'absolute',
                top: '0px', left: '0px', zIndex: '500', width: '100%',
                height: '100%', background: 'rgba(0,0,0,.1)'};
            var loading = <div style={refreshing_style}>
                <Image style={{'width':'100px'}} src={'../../static/images/loading.gif'} />
            </div>;
            content.push(loading);
        }

        if (this.props.nowrapper) {
            return (content);
        }
        else if (this.state.loaded) {
            return (
                <div className={this.props.className} style={{'position': 'relative'}}>{content}</div>
            );
        }
        else {
            return (
                <Wrapper className={this.props.className} loaded={this.state.loaded} content={content} />
            );
        }

    }
}

export default ListWithChildren;
