import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {ajaxWrapper} from 'functions';
import {Button, TextInput, Json_Input, CSSInput} from 'library';


//Example
// var gender =  {'value':'','name':'gender','label':'Gender','options':[{'value':'Pick One','text':'Pick One'}, {'value':'Male', 'text':'Male'}, {'value':'Female','text':'Female'},{'value':'Other','text':"I don't identify as either"}]}

var BOOLEANS = [
    {'text':'True', value:true},
    {'text':'False', value:false},
];

class Select extends Component {
    static component_name = 'Select'
    constructor(props) {
        super(props);
        this.state = {options:[]}

        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'defaultoption'} name={'defaultoption'} />,
                <TextInput label={'label'} name={'label'} />,
                <Select label={'required'} name={'required'} boolean={true} defaultoption={false} />,
                <Select label={'boolean'} name={'boolean'} boolean={true} defaultoption={false} />,
                <Select label={'Radio'} name={'radio'} boolean={true} defaultoption={false} />,
                <Json_Input label={'options'} name={'options'} />,
                <TextInput label={'Options URL'} name={'optionsUrl'} />,
                <Json_Input label={'Options URL Map'} name={'optionsUrlMap'} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'css'} name={'style'}/>,
                <CSSInput label={'label css'} name={'label_style'}/>,
            ],
        }

        this.optionsCallback = this.optionsCallback.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeSelection = this.removeSelection.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {
        this.refreshData();


    }

    componentDidUpdate (prevProps, prevState, snapshot){
        if (this.props.optionsUrl != prevProps.optionsUrl){
            this.refreshData();
        }

        var optionDict = this.props.options;
        if (this.props.boolean){
            optionDict = BOOLEANS;
        } else if (!this.props.options) {
            optionDict = this.state.options;
        }
        if (optionDict != this.state.options){
            this.setState({options: optionDict});
        }
    }

    refreshData() {
        if (this.props.optionsUrl && this.props.optionsUrl != '') {
            //Subscribe to all variables in optionsUrl
            window.cmState['subscribe'](this, this.props.optionsUrl);

            var options_url = resolveVariables({'options_url':this.props.optionsUrl}, window.cmState.getGlobalState())['options_url']
            console.log("Options URL Select", options_url, this.props.optionsUrl)
            ajaxWrapper("GET", options_url, {}, this.optionsCallback.bind(this));
        }
    }

    optionsCallback(value) {
        console.log("Options Callback", value);
        var options = [];
        for (var index in value) {
            var textValue = value[index];
            var valueValue = value[index];

            if (this.props.optionsUrlMap) {
                var resolvedValue = resolveVariables(this.props.optionsUrlMap, textValue);
                textValue = resolvedValue['text']
                valueValue = resolvedValue['value']
            }
            options.push({'text':textValue,'value':valueValue});

        }
        console.log("Options",options);
        this.setState({options:options})
    }

    handleChange = (e) => {
        var selection = e.target.value;
        var newState = {}

        if (this.props.multiple == true) {
            var value = this.props.value;
            var index = value.indexOf(selection);
            if (index == -1) {
                value.push(selection)
            }
            newState[this.props.name] = value;
        }
        else if (this.props.boolean){
            // If the select is designated as a boolean, we need to convert the value
            if (selection == 'true'){
                newState[this.props.name] = true;
            } else {
                newState[this.props.name] = false;
            }
        }
        else {
            newState[this.props.name] = selection;
        }

        console.log("New State",newState);
        this.props.setFormState(newState);
    }

    removeSelection(selection) {
        var value = this.props.value;
        var index = value.indexOf(selection);
        value.splice(index,1);

        var newState = {}
        newState[this.props.name] = value;
        this.props.setFormState(newState);
    }

    render() {
        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        var label = null;
        if (this.props.label) {
            console.log("Label Style", this.props.label_style)
            var label = <label style={this.props.label_style}>{this.props.label}</label>;
        }

        //Check if default value should be used
        if (this.props.multiple == true) {
            var value = this.props.value;
            if (this.props.value) {
                if (value.length == 0) {
                    value = this.props.defaultoption;
                }
            }
        }
        else {
            var value = String(this.props.value);
            if (value == '' || value == 'undefined') {
                value = this.props.defaultoption;
            }
        }

        var optionDict = this.state.options;
        var options = [];

        // Check if default is inside options and add it if not
        var found_default = null;
        for (var index in optionDict) {
            if (optionDict[index]['value'] == this.props.defaultoption) {
                found_default = optionDict[index];
            }
        }

        if (this.props.radio){
            var type = " btn-outline-secondary";
            // Render Radio Components
            // Create JSX for select options
            for (var index in optionDict) {
                var active = '';
                if (value == String(optionDict[index]['value'])) {
                    active = " active";
                }
                options.push(<label className={"btn" + active + type}>
                    <input style={{display:"none"}}  type="radio" name={this.props.name} key={index} value={String(optionDict[index]['value'])}
                        onClick={this.handleChange} />{optionDict[index]['text']}
                </label>);
            }

            var content = <div className="radios">{options}</div>;

        }
        else {
            // Render Select Component
            if (!found_default) {
                options.push(<option key={-1} value={this.props.defaultoption}>{this.props.defaultoption}</option>);
            }

            // Create JSX for select options
            for (var index in optionDict) {
                options.push(<option key={index} value={String(optionDict[index]['value'])}>{optionDict[index]['text']}</option>);
            }

            var multipleSelections = []

            if (this.props.multiple == true) {
                var optionsDict = {}
                for (var index in optionDict) {
                    optionsDict[optionDict[index]['value']] = optionDict[index]['text']
                }
                for (var index in value) {
                    multipleSelections.push(
                        <Button key={this.props.name + '-' + index} clickHandler={() => this.removeSelection(value[index])}
                        type={'danger'} text={optionsDict[value[index]]} />);
                }
            }

            var content = <select className="form-control" name={this.props.name} onChange={this.handleChange} value={value}>
                {options}
            </select>;

        }

        return (
            <div className={"form-group " + this.props.className} style={this.props.style} >
                {label}
                {multipleSelections}
                {content}
                <div style={{width:'100%', clear:'both'}} ></div>
            </div>
        )
    }

}

export default Select;
