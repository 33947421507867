import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {If, Alert, TextInput, Button, EmptyModal, FormWithChildren, Header, Select, CSSInput, Json_Input} from 'library';

class ListInput extends Component {
    static component_name = 'ListInput';
    constructor(props) {
      super(props);


      this.state = {'error': null, current_val:'', rows: []}

      this.config = {
          form_components: [
              <Json_Input label='Row Inputs' name='row_inputs' />,
              <TextInput label='Row Inputs Resolve' name='row_inputs_resolve' />,
              <TextInput label={'label'} name={'label'} />,
              <TextInput label={'name'} name={'name'} />,
              <Select label={'required'} name={'required'} boolean={true} />,
              <TextInput label={'class'} name={'className'} />,
              <CSSInput label={'css'} name={'style'} default={{}} />,
              <CSSInput label={'label css'} name={'label_style'} default={{}} />,
          ],
      }

      this.handle_change = this.handle_change.bind(this);
      this.show_modal = this.show_modal.bind(this);
      this.hide_modal = this.hide_modal.bind(this);
      this.add_row = this.add_row.bind(this);
      this.delete_row = this.delete_row.bind(this);
      this.build_json = this.build_json.bind(this);
      this.set_json_state = this.set_json_state.bind(this);
    }

    componentDidMount() {
        if (this.state.rows.length == 0) {
            this.add_row();
        }

        if (this.props.props_data && this.props.row_inputs) {
            if (this.props.props_data[0][this.props.row_inputs[0]['name']]) {
                var rows = this.props.props_data;
                this.setState({rows:rows})
            }
        }
        if (this.props.value) {
            var rows = this.props.value;
            this.setState({rows:rows})
        }
    }

    handle_change = (e) => {
       var name = e.target.getAttribute("name");
       var newState = {};
       var value = e.target.value;

       if (value == '') {
           newState[name] = value;
           this.setState({error: null}, this.props.setFormState(newState));
       }
       else {
           try {
             newState[name] = JSON.parse(value);
             this.setState({error: null}, this.props.setFormState(newState));
           }
           catch(err) {
             this.setState({error: err.toString(), current_val: value});
           }
       }
    }

    show_modal() {
        var json = this.props.value;
        if (typeof(json) == 'string') {
            try {
                json = JSON.parse(json);
            }
            catch {
                json = {}
            }

        }

        var rows = [];
        for (var index in json) {
            var temp_dict = {key: index, value: json[index]}
            rows.push(temp_dict);
        }

        if (rows.length == 0) {
            rows = [{key:'', value:''}]
        }
        this.setState({modal: true, rows:rows})
    }

    hide_modal() {
        this.setState({modal: false})
    }

    add_row() {
        var rows = this.state.rows;
        rows.push({key:'', value:''})
        this.setState({rows: rows})
    }

    delete_row() {
        var rows = this.state.rows;
        rows.pop();
        this.setState({rows:rows})
    }

    build_json() {
        var new_json = {};
        for (var index in this.state.rows) {
            var row = this.state.rows[index];
            if (row['key'] != '') {
                new_json[row['key']] = row['value']
            }
        }
        console.log("New Json", new_json)

        var newState = {};
        newState[this.props.name] = new_json

        this.setState({error: null, modal: false}, this.props.setFormState(newState));
    }

    set_json_state(name, state) {

        var row_titles = [];
        var row_inputs = this.props.row_inputs;
        if (this.props.row_inputs_resolve) {
            row_inputs = this.props.row_inputs_resolve
        }

        for (var input_index in row_inputs) {
            var input = row_inputs[input_index];
            row_titles.push(input['name'])
        }

        var rows = this.state.rows;
        for (var key in state) {
            for (var title_index in row_titles) {
                var title = row_titles[title_index]
                if (key.startsWith(title + '_')) {
                    var row = parseInt(key.substring(title.length + 1, key.length))
                    rows[row][title] = state[key]
                }
            }
        }

        this.setState({rows:rows})
        var newState = {};

        newState[this.props.component_name] = rows;

        if (this.props.props_data) {
            if (this.props.props_data[0][this.props.row_inputs[0]['name']]) {
                newState["props_data"] = rows;
            }
        }


        this.props.setFormState(newState)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value != prevProps.value) {
            this.setState({rows:this.props.value});
        }
    }


    render() {

        var row_inputs = this.props.row_inputs;
        if (this.props.row_inputs_resolve) {
            row_inputs = this.props.row_inputs_resolve
        }

        var rows = [];
        if (row_inputs) {
            var col_num = (12/row_inputs.length).toFixed(0);
        }
        else {
            var col_num = '6'
        }


        for (var input_index in row_inputs) {
            var input = row_inputs[input_index]
            rows.push(<Header size={5} style={{textTransform:'capitalize'}} className={"col-" + col_num} text={input['placeholder']} />)
        }


        for (var index in this.state.rows) {
            var row = this.state.rows[index];
            for (var input_index in row_inputs) {
                var input = row_inputs[input_index]
                rows.push(<TextInput className={"col-" + col_num} name={input['name'] + '_' + index} placeholder={input['placeholder']} default={row[input['name']]} />)
            }
        }



        var layout = '';
        if (this.props.className) {
            layout = this.props.className;
        }

        var label = null;
        if (this.props.label && this.props.label != '') {
            label = <label>{this.props.label}</label>;
        }

        if (typeof(this.props.value) == 'string') {
            var json = this.props.value;
        }
        else {
            var json = JSON.stringify(this.props.value);
        }

        var remove_row = null;
        if (this.state.rows.length > 1) {
            remove_row = <Button type={'danger'} text={'Delete Row'} className="col-4"  onClick={this.delete_row} />
        }

        return (
              <div className={"form-group " + layout}>
                {label}
                <div className="container" style={{padding:'10px'}}>
                    <FormWithChildren row={true} className="row"
                        autoSetGlobalState={true} setGlobalState={this.set_json_state} globalStateName={'json'}
                        dont_resolve_anything={this.props.dont_resolve_anything}>
                        {rows}
                    </FormWithChildren>
                    <div className="row">
                        <Button type={'success'} text={'Add Row'} className="col-4" onClick={this.add_row} />
                        {remove_row}
                    </div>
                </div>
              </div>
        )


    }
}

export default ListInput;
