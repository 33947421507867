import {FormWithChildren, LogInForm, SignUpForm, ListWithChildren, Div, If, Break, NumberInput,
        BooleanInput, TextInput, Select, TextArea, FileInput, ImageInput, Button, Header, Paragraph, CSSInput,
        Container, EmptyModal, PasswordInput, Json_Input, Function_Input, PasswordResetRequest,
        CardWithChildren, Icons, Navbar,Link, Image, Embed, MultiImageViewer, UnorderedList, ListItem, Span, Icon, Instance,
        Script, DateTimePicker, NavLink, Video, Hover, Alert, BrowserType, DisplaySize, Date,
        TableRow, TableData, TableHeader, Table, CopyToClipboard, CheckGroup, Checkbox, StripeMain,
        Stars, StarInput, Math, Carousel, MultiLineText, HTMLInput, HTMLInputDisplay, LogicInput, LogicDisplay, ListInput, SetGlobalState,
        HTMLDisplay, DateFormat, ProgressBar, SetDate, LineChart, Decimal} from 'library';

let ComponentList = [
    Alert,
    Date,
    Paragraph,
    Header,
    Container,
    Div,
    Break,
    Button,
    Stars,
    StarInput,
    FormWithChildren,
    TextInput,
    TextArea,
    ImageInput,
    CardWithChildren,
    Icons,
    Navbar,
    NavLink,
    Link,
    Image,
    Embed,
    MultiImageViewer,
    UnorderedList,
    ListItem,
    Span,
    Icon,
    LogInForm,
    PasswordInput,
    ListWithChildren,
    If,
    LogInForm,
    SignUpForm,
    PasswordResetRequest,
    Instance,
    DateTimePicker,
    Select,
    Video,
    Script,
    Hover,
    BrowserType,
    DisplaySize,
    Table,
    TableRow,
    TableData,
    TableHeader,
    CopyToClipboard,
    CheckGroup,
    Checkbox,
    NumberInput,
    EmptyModal,
    StripeMain,
    Json_Input,
    CSSInput,
    Math,
    Carousel,
    MultiLineText,
    HTMLInput,
    HTMLInputDisplay,
    LogicInput,
    LogicDisplay,
    ListInput,
    SetGlobalState,
    HTMLDisplay,
    DateFormat,
    ProgressBar,
    SetDate,
    LineChart,
    Decimal,
];


var ComponentNameLookup = {}
for (var i in ComponentList){
    var value = ComponentList[i];
    ComponentNameLookup[value.component_name] = value;
}

export default ComponentNameLookup;
