import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {Json_Input, LogicDisplay, Select, FormWithChildren, Button, TextInput, EmptyModal} from 'library';
import jsonLogic from 'json-logic-js';

var logic_options = [
    {text:"Equal To", value:"=="},
    {text:"Greater Than", value:">"},
    {text:"Greater Than Or Equal To", value:">="},
    {text:"Less Than", value:"<"},
    {text:"Less Than Or Equal To", value:"<="},
    {text:"Or", value:"or"},
    {text:"And", value:"and"}
]

class LogicButton extends React.Component {
    constructor(props) {
        super(props);
        this.select = this.select.bind(this);
    }

    select() {
        this.props.select(this.props.value)
    }

    render() {
        return (
            <Button type='primary' text={this.props.text} onClick={this.select} />
        )
    }
}

class LogicInput extends React.Component {
    static component_name = 'LogicInput';
    constructor(props) {
        super(props);
        var logic = {} || this.props.logic;
        this.state = {comparator: undefined, rows: 2, and_or: false, values:[null, null], logic: logic}

        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} />,
                <TextInput label={'label'} name={'label'} />,
                <Json_Input label={'Logic'} name={'logic'} />,
            ],
        }

        this.select = this.select.bind(this);
        this.submit = this.submit.bind(this);
        this.setFormState = this.setFormState.bind(this);
        this.addRow = this.addRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
    }

    componentDidUpdate (prevProps, prevState, snapshot){
        if (this.props.logic != prevProps.logic){
            this.setState({logic:this.props.logic});
        }
    }

    select(value) {
        if (value != 'and' && value != 'or') {
            this.setState({comparator: value, modal: true})
        }
        else {
            this.setState({comparator: value, modal: false, and_or: true})
        }

    }

    submit(value) {
        var logic = {}
        var values = [value.value1, value.value2];
        logic[this.state.comparator] = values;

        var newState = {}
        newState[this.props.name] = logic;
        this.setState({modal: false, values:values, logic: logic})
        this.props.setFormState(newState);
    }

    setFormState(newState) {
        var values = this.state.values;

        var logic = {};
        for (var name in newState) {
            var index = parseInt(name);
            values[index] = newState[index];
            logic[this.state.comparator] = values;
        }


        var newState = {}
        newState[this.props.name] = logic;
        this.setState({modal: false, values:values, logic: logic})
        this.props.setFormState(newState);
    }

    addRow() {
        var values = this.state.values;
        values.push(null);
        this.setState({rows: this.state.rows + 1, values: values})
    }

    removeRow() {
        var values = this.state.values;
        values.pop();
        this.setState({rows: this.state.rows - 1, values:values})
    }

    render() {

        var depth = 0;
        var defaults = {}
        var fields = [];

        var buttons = [];
        for (var index in logic_options) {
            var option = logic_options[index];
            buttons.push(<LogicButton text={option['text']} value={option['value']} select={this.select} />)
        }

        var label = null;
        if (this.props.label && this.props.label != '') {
            label = <label style={this.props.label_style}>{this.props.label}</label>;
        }

        var choose_comparator = <div>
        <p>Click To Add A Logic Function</p>
        {buttons}
        </div>;
        if (this.state.comparator != undefined) {
            var choose_comparator = <Button text='Remove Function' type='danger' onClick={() => this.setState({comparator:undefined, logic:{}, and_or:false})} />
        }

        var and_or_rows = [];
        console.log("and or", this.state.and_or)
        if (this.state.and_or) {
            console.log("Here", this.state.rows);
            for (var i = 0; i < this.state.rows; i++) {
                and_or_rows.push(<LogicInput setFormState={this.setFormState} name={i} />)
                if (i + 1 < this.state.rows) {
                    and_or_rows.push(<p style={{fontSize:'20px', fontWeight:'bold', textTransform:'uppercase', margin: '5px'}}>{this.state.comparator}</p>)
                }
                else {
                    and_or_rows.push(<div style={{height:'20px'}}></div>)
                    and_or_rows.push(<Button text='Add Condition' type='info' onClick={this.addRow}/>)
                    if (this.state.rows > 2) {
                        and_or_rows.push(<Button text='Remove Last Condition' type='delete' onClick={this.removeRow}/>)
                    }
                }
            }
        }


        return (
            <div>
                {label}
                <LogicDisplay logic={this.state.logic} />

                {choose_comparator}

                <div style={{marginLeft:'20px', borderLeft:'1px solid black', paddingLeft:'3px'}}>
                    {and_or_rows}
                </div>

                <EmptyModal show={this.state.modal} onHide={() => this.setState({modal:false})}>
                    <div style={{padding: '10px'}}>
                        <FormWithChildren submit={this.submit}>
                            <TextInput label="Value 1" name="value1" />
                            <TextInput label="Value 2" name="value2" />
                        </FormWithChildren>
                    </div>
                </EmptyModal>

            </div>
        );
    }
}

export default LogicInput;
