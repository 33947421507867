import React from 'react';

function get_children(context) {
    var children = [];
    if (context.children != undefined) {
        //weirdly enough this means more than one child
        if (context.children.length > 0) {
            for (var index in context.children) {
                var child = context.children[index];
                children.push(child);
            }
        }
        else {
            var child = context.children;
            if (child.length != 0) {
                children.push(child);
            }
        }
    }

    return children;
}


export default get_children;
