import React, { Component } from 'react';
import {ajaxWrapper, sort_objects, resolveVariables} from 'functions';
import {Wrapper, NumberInput, Button, FormWithChildren,
    TextInput, If, Select, Alert,
    Navbar, NavLink, Sidebar, Checkbox, Break, Json_Input} from 'library';
import ComponentNameLookup from './componentNameLookup.js';
import AddChildComponent from './addChildComponent.js';
import DuplicateComponent from './duplicateComponent.js';
import ComponentList from './componentList.js';
import {Helmet} from "react-helmet";


class PageBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            components: [],
            selected_component: null,
            loaded: false,
            saving: false,
            pagegroup: this.props.page_group_id,
            building_blocks: null,
            project_components: null
        };

        this.addComponent = this.addComponent.bind(this);
        this.setComponent = this.setComponent.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.save = this.save.bind(this);
        this.save_failure = this.save_failure.bind(this);
        this.delete = this.delete.bind(this);
        this.reload = this.reload.bind(this);
        this.load = this.load.bind(this);

        this.setGlobalStateName = this.setGlobalStateName.bind(this);
        this.remove_component = this.remove_component.bind(this);
        this.components_to_remove = this.components_to_remove.bind(this);
        this.addBuildingBlock = this.addBuildingBlock.bind(this);
        this.page_group_callback = this.page_group_callback.bind(this);
        this.change_parent = this.change_parent.bind(this);

        this.create_component_tree = this.create_component_tree.bind(this);
        this.display_creator = this.display_creator.bind(this);
        this.duplicate_page = this.duplicate_page.bind(this);
    }

    setGlobalStateName(name,state) {
        this.setState(state);
    }

    setGlobalState(name, state) {
      var components = this.state.components;
      for (var index in this.state.components){
          if (this.state.components[index] == this.state.selected_component){
              var context = this.state.selected_component['props'];

              //This is for single key updates coming from Form
              for (var key in state){
                  context[key] = state[key];

                  if (key == 'order'){
                      this.state.selected_component['order'] = parseInt(state['order']);
                  }
                  else if (key == 'parent'){
                      this.state.selected_component['parent'] = state['parent'];
                  }
              }

          }
      }

      this.setState({components:components});
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/modelWebsite/pagegroup/?reference_name=building_blocks&related=pages', {}, this.page_group_callback);
        ajaxWrapper('GET', '/api/modelWebsite/pagegroup/?can_be_imported=true&related=pages', {}, this.page_group_callback);
    }

    page_group_callback(result) {
        console.log("Result", result)
        var resolved_pages = []

        console.log("Pages", pages, result[0], result[0]['pagegroup']);
        var key_name = result[0]['pagegroup']['reference_name'];
        if (result.length > 1){
            key_name = 'project_components';
        }

        for (var j in result){
            var pages = result[j]['pagegroup']['pages'];


            for (var i in pages) {
                var page = pages[i]['page']['components'];

                var wrapper = {
                    "key":-1,
                    "type":"Div",
                    "props":{'display_name': pages[i]['page']['name']},
                };
                page.unshift(wrapper);

                var existing_keys = [];
                for (var j in page){
                    page[j]['key']  = parseInt(page[j]['key']);

                    if (!('order' in page[j]) || !(page[j]['order'])){page[j]['order'] = 0;}
                    page[j]['order']  = parseInt(page[j]['order']);

                    if ('parent' in page[j]){page[j]['parent']  = parseInt(page[j]['parent']);}
                    existing_keys.push(page[j]['key']);
                }

                for (var j in page){
                    var component = page[j];
                    component['class'] = ComponentNameLookup[component['type']];
                    if (component['key'] > -1 && (!('parent' in component) || existing_keys.indexOf(component['parent']) == -1)){
                        component['parent'] = -1;
                    }
                }
                resolved_pages.push(pages[i]['page']);
            }
        }

        var state = {};
        state[key_name] = resolved_pages;
        this.setState(state, function(){
            if (this.state.building_blocks instanceof Array && this.state.project_components instanceof Array){
                this.load_page_data();
            }
        });
    }

    load_page_data(){
        if (this.props.page_id) {
          ajaxWrapper('GET','/api/modelWebsite/page/' + this.props.page_id + '/', {}, this.load)
        }
        else if (this.props.show) {
          ajaxWrapper('GET','/api/modelWebsite/page/?url=' + this.props.route, {}, this.load)
        }
        else {
          this.setState({loaded:true});
        }
    }

    load(result) {
        console.log("Pagebuilder loading page:", result);
        var page = result[0]['page'];

        if (window.location.pathname.indexOf('pagebuilder') > -1) {
            var page_state = page['page_state']
        }
        else {
            var page_state = resolveVariables(page['page_state'], window.cmState.getGlobalState())
        }

        console.log("Page State", page_state)
        for (var index in page_state) {
            window.cmState.setGlobalState(index, page_state[index])
        }

        var components = page['components'];
        var component_tree = this.create_component_tree(components);

        var loaded_components = [];
        var building_blocks_to_refresh = [];
        var building_blocks_to_refresh_ids = [];

        var building_block_key_map = this.assign_building_block_parents_recursivly({}, component_tree);

        for (var i in components){
            var component = components[i];
            component['class'] = ComponentNameLookup[component['type']];
            if (!('order' in component) || !(component['order'])){component['order'] = 0;}

            if (component.building_block_id && 'not_editable' in component.props && component.props['not_editable']){
                building_blocks_to_refresh.push(component);
                building_blocks_to_refresh_ids.push(component['building_block_id']);
            }
        }

        for (var i in components){
            var component = components[i];
            if (component.building_block_parent && building_blocks_to_refresh_ids.indexOf(component.building_block_parent) > -1){
                console.log('a');
            }
            else if (component.building_block_id && building_blocks_to_refresh_ids.indexOf(component.building_block_id) > -1) {
                console.log('a');
            }
            else {
                loaded_components.push(component);
            }
        }

        for (var i in building_blocks_to_refresh){
            var component = building_blocks_to_refresh[i];
            if (component['parent'] in building_block_key_map){
                console.log('No support for embedded building block updating');
                continue;
            }

            var building_block = this.get_building_block(component['building_block_id']);
            if (building_block){
                loaded_components = this.update_component_list(
                    loaded_components, building_block['components'],
                    component['parent'], component['order'], component['building_block_id']
                );
            }
        }

        this.setState({
            components: loaded_components,
            selected_component: null,
            name:page.name,
            url: page.url,
            page_state: page.page_state,
            pagegroup: page.pagegroup_id,
            loaded:true,
            saving: false
        });
    }

    assign_building_block_parents_recursivly (building_block_key_map, component_tree, building_block_parent){
        var new_building_block_parent = building_block_parent;

        for (var i in component_tree){
            var component = component_tree[i];

            if (building_block_parent){
                building_block_key_map[component['key']] = building_block_parent;
                component['building_block_parent'] = building_block_parent;
                new_building_block_parent = building_block_parent;
            }

            else if ('building_block_id' in component && 'not_editable' in component.props && component.props['not_editable']){
                new_building_block_parent = component['building_block_id'];
            }

            if ('children' in component){
                this.assign_building_block_parents_recursivly(building_block_key_map, component['children'], new_building_block_parent);
            }

            new_building_block_parent = null;
        }

        return building_block_key_map;
    }

    addBuildingBlock (building_block, parent_index, order) {
        //List of components currently on page
        var existing_components = this.state.components;
        //List of components to add to page
        var new_components = building_block['components'];

        //Running counter of highest integer value used as a key
        var next_order = 0;
        for (var i in this.state.components){
            if (this.state.components[i].order > next_order){
                next_order = this.state.components[i].order;
            }
        }

        if (!order){
            order = next_order + 1;
        }

        var id = null;
        if ('id' in building_block){
            id = building_block['id'];
        }

        console.log("Building Block", building_block, existing_components, parent_index);

        this.update_component_list(existing_components, building_block['components'], parent_index, order, id);

        console.log("Components after adding duplicate", existing_components);
        this.setState({components:existing_components});
    }

    get_building_block(id){
        var building_block = null;
        for (var i in this.state.building_blocks){
            if (building_block){break;}
            if (this.state.building_blocks[i]['id'] == id){
                building_block = this.state.building_blocks[i];
            }
        }

        for (var i in this.state.project_components){
            if (building_block){break;}
            if (this.state.project_components[i]['id'] == id){
                building_block = this.state.project_components[i];
            }
        }

        return building_block;
    }

    get_next(components){
        var next_key = 0;
        var next_order = 0;

        for (var i in components){
            var component = components[i];
            if (parseInt(component['key']) > next_key){
                next_key = parseInt(component['key']);
            }

            if (parseInt(component['order']) > next_order){
                next_order = parseInt(component['order']);
            }
        }

        return {'key': next_key + 1, 'order': next_order + 1};
    }

    update_component_list(existing_components, components, parent_index, next_order, id){
        //Map of old key values to new key values
        var parent_key_map = {null: parent_index};
        var next = this.get_next(existing_components);

        var new_components = [];
        //Add values to map and apply new keys to components
        for (var index in components) {
            var component = components[index];
            var new_component = JSON.parse(JSON.stringify(component));
            new_component.class = component['class'];

            next['key'] += 1;
            parent_key_map[parseInt(new_component['key'])] = next['key'];
            new_component['key'] = next['key'];
            new_components.push(new_component);
        }

        //Check map for parent values and set to new keys
        for (var index in new_components) {
            var component = new_components[index];

            if (parent_key_map[parseInt(component['parent'])]) {
                component['parent'] = parent_key_map[parseInt(component['parent'])];
                if (id){
                    component['building_block_parent'] = id;
                }
            }
            else {
                console.log("Parent key not found", component, parent_key_map);
                //This is supposed to be the parent component
                component['order'] = next_order;
                component['parent'] = parent_index;
                if (id){
                    component['building_block_id'] = id;
                    if (!('not_editable' in component.props)){
                        component.props['not_editable'] = true;
                    }
                }
            }
            existing_components.push(component);
        }

        return existing_components;
    }


    addComponent(reference, parent = null) {
        var components = this.state.components;
        var next = this.get_next(components);

        var component = {
            type: reference.component_name,
            class: reference, props: {},
            parent:parent,
            order: next['order'],
            key: next['key'],
        }

        components.push(component);
        this.setState({components: components});
    }

    setComponent(key) {
        var selected_component = null;
        for (var index in this.state.components){
            if (key == this.state.components[index]['key']){
                selected_component = this.state.components[index];
            }
        }
        this.setState({selected_component: selected_component});
    }

    save() {
        var submitUrl = '/api/modelWebsite/page/'
        if (this.props.page_id) {
            submitUrl += this.props.page_id + '/'
        }

        //copy list
        var components = [];
        for (var i in this.state.components) {
            var component = this.state.components[i];
            delete component['class'];
            //This is generated by the display_creator function.
            //We could remove this line if that function properly deep copied
            //delete component['props']['children'];
            components.push(component);
            if (components[i]['props'] && components[i]['props']['children']) {
                delete components[i]['props']['children'];
            }
        }

        //formatting url to work for what we need.
        var url = this.state.url;
        if (url && url != '') {
            if (!url.endsWith("/")) {
                url = url + '/';
            }
            url = url.toLowerCase();
        }

        var data = {
            components:components,
            name: this.state.name,
            url: url,
            page_state: this.state.page_state,
            pagegroup: this.state.pagegroup
        };

        console.log("Save", submitUrl, data);
        ajaxWrapper('POST',submitUrl, data, this.reload, this.save_failure);

        this.setState({saving: true, selected_component: null});
    }

    duplicate_page() {
        var submitUrl = '/api/modelWebsite/page/'

        //copy list
        var components = this.state.components.slice(0);
        for (var i in components) {
            delete components[i]['class'];
            //This is generated by the displayCreator function.
            //We could remove this line if that function properly deep copied
            delete components[i]['props']['children'];
        }

        //formatting url to work for what we need.
        var url = this.state.url;
        if (url && url != ''){
            if (!url.endsWith("/")) {
                url = url + '/';
            }
            url = url.toLowerCase();
        }

        var data = {
            components:components,
            name: 'Copy Of ' + this.state.name,
            url: url,
            page_state: this.state.page_state,
            pagegroup: this.state.pagegroup
        };

        console.log("Save", submitUrl, data);
        ajaxWrapper('POST',submitUrl, data, this.reload, this.save_failure);

        this.setState({saving: true, selected_component: null});
    }

    save_failure(xhr) {
        var text = [<h3>Error Encountered While Saving.  Please Contact Technical Support With The Following Message</h3>];
        var split_message = xhr.responseText.split(/\r?\n/);
        for (var i in split_message){
            var chunk = split_message[i];
            text.push(<div>{chunk}</div>);
        }

        var saving_content = <Alert style={{textAlign:'left'}} type={'danger'} text={text} />;
        this.setState({saving_content: saving_content});
    }

    delete() {
        if (this.props.page_id) {
            ajaxWrapper('POST','/api/modelWebsite/page/' + this.props.page_id + '/delete/', {}, () => window.location = '/pageList/');
        }
        else {
            window.location = '/pageList/';
        }
    }

    reload(result) {
        if (!this.props.page_id) {
            window.location.href = '/pagebuilder/' + this.props.page_group_id + '/' + result[0]['page']['id'] + '/'
        }
        else {
            ajaxWrapper('GET','/api/modelWebsite/page/' + this.props.page_id + '/', {}, this.load)
        }
    }

    //creates a rendering for the preview on pagebuilder or the real deal if show = true
    //lookup is a dictionary of keys with their children keys in a list as a key
    display_creator(component_tree) {
        var display = [];
        component_tree = sort_objects(component_tree, ['order']);

        for (var index in component_tree) {
            var component = component_tree[index];

            try {
                var TempComponent = component['class'];
                var props = {setGlobalState: this.setGlobalState};
                for (var key in component.props){
                    props[key] = component.props[key];
                }

                if (new component['class']().config['can_have_children']) {
                    props['children'] = sort_objects(this.display_creator(component['children']), ['order']);
                }

                var rendered_component = <TempComponent {...props} />;

                if (this.state.selected_component && this.state.selected_component['key'] == component['key']) {
                    var display_style = {boxShadow:'2px 2px 10px #0089ff', minWidth: '1px', minHeight: '1px'};
                    rendered_component = <div style={display_style}>{rendered_component}</div>;
                }

                display.push(rendered_component);
            }
            catch(error) {
                console.log("Component", component);
                console.log("Error likely caused by a component that doesn't exist or isn't imported", error, component);
            }
        }

        return display;
    }

    components_to_remove(parent_id) {
        var components = this.state.components;
        var remove_components = [parent_id];

        for (var index in components) {
            var component = components[index];
            if (parseInt(component['parent']) == parseInt(parent_id)) {
                remove_components.push(...this.components_to_remove(component['key']));
            }
        }

        return remove_components;
    }

    remove_component() {
        var components = this.state.components;
        var components_to_remove = this.components_to_remove(this.state.selected_component['key']);

        var parent_dict = {}
        var new_components = [];
        var found = false;
        for (var index in components) {
            var component = components[index];

            if (components_to_remove.indexOf(component['key']) == -1) {
                new_components.push(components[index]);
            }
        }
        console.log("new_components", new_components)

        this.setState({components: new_components, selected_component: null});
    }

    create_component_tree(context, key) {
        var component_tree = [];
        var existing_keys = [];
        for (var index in context) {
            existing_keys.push(parseInt(context[index]['key']));
        }

        for (var index in context) {
            var component = context[index];
            if (key){
                if (parseInt(component['parent']) == parseInt(key)) {
                    component['children'] = sort_objects(this.create_component_tree(context, component['key']), ['order']);
                    component_tree.push(component);
                }
            }
            else {
                if (!component['parent'] || existing_keys.indexOf(parseInt(component['parent'])) == -1) {
                    component['children'] = sort_objects(this.create_component_tree(context, component['key']), ['order']);
                    component_tree.push(component);
                }
            }
        }

        component_tree = sort_objects(component_tree, ['order']);

        return component_tree;
    }

    change_parent(drag, drop) {
        console.log(drag, drop);

        var components = this.state.components;
        for (var i in components) {
            var component = this.state.components[i];
            if (component['key'] == drag) {
                component['parent'] = drop;
            }
        }

        this.setState({components: components});
    }

    render() {
        var window_height = window.innerHeight;

        var component_tree = this.create_component_tree(this.state.components);
        var component_key_map = {};
        for (var i in this.state.components){
            var component = this.state.components[i];
            if ('parent' in component){
                component_key_map[component['key']] = component['parent'];
            }
        }

        if (this.props.show) {
            var display = this.display_creator(component_tree);

            var content = <div>
                {display}
            </div>
        }
        else {
            var display = this.display_creator(component_tree);

            var componentPropsForm = null;

            //if component is selected make the props form
            if (this.state.selected_component) {
                var selected_component = this.state.selected_component;

                var components = [];
                if ('class' in selected_component){
                    var config = new selected_component['class']().config
                    components = config['form_components'];
                }

                var default_order = 0;
                if ('order' in selected_component && selected_component['order']){
                    default_order = selected_component['order'];
                }

                console.log("selected_component", selected_component);
                var new_components = [
                    <TextInput label={'Display Name'} name={'display_name'} default={selected_component.props['display_name']} />,
                    <NumberInput label={'Order'} name={'order'} default={default_order} />,
                    <TextInput label={'Parent'} name={'parent'} default={selected_component['parent']} />
                ];

                for (var index in components) {
                    var component = components[index];
                    var value = selected_component['props'][component.props['name']];
                    var passedData = {parentIndex: selected_component['key'], 'addComponent': this.addComponent};
                    if (typeof(value) != 'undefined') {
                        passedData['default'] = value;
                        passedData['value'] = value;
                    }
                    component = React.cloneElement(component, passedData);
                    new_components.push(component);
                }

                if (selected_component.building_block_id){
                    new_components.push(<Checkbox name={'not_editable'} label={'Not Editable'} secret_value={true} default={selected_component.props['not_editable']} />);
                    new_components.push(<Break/>);
                }

                if (config['can_have_children'] && !selected_component.props['not_editable']) {
                    new_components.push(<AddChildComponent label="Add Child" name="children" parentIndex={selected_component['key']}
                    project_components={this.state.project_components} building_blocks={this.state.building_blocks}
                    addComponent={this.addComponent} default={""} addBuildingBlock={this.addBuildingBlock} />)
                    new_components.push(<Break/>);
                }

                new_components.push(<DuplicateComponent addBuildingBlock={this.addBuildingBlock} components={this.state.components} selected_component={selected_component} />)
                new_components.push(<Button default={''} name='delete' deleteType={true} text={'Delete'} onClick={this.remove_component} type='danger' />)

                var key = selected_component['key'] + '_pagebuilder_component_form';
                var componentPropsForm = <FormWithChildren key={key} globalStateName={key}
                    autoSetGlobalState={true} setGlobalState={this.setGlobalState} dont_resolve_anything={true} >
                    {new_components}
                </FormWithChildren>;
            }

            var componentList = <ComponentList component_tree={component_tree} component_key_map={component_key_map} setComponent={this.setComponent}
                selected_component={this.state.selected_component} change_parent={this.change_parent} />;

            var componentColumn = <div>
                <h2>Component List</h2>
                <AddChildComponent label="Add Component" name="children" parentIndex={null}
                    project_components={this.state.project_components} building_blocks={this.state.building_blocks}
                    addComponent={this.addComponent} default={""} addBuildingBlock={this.addBuildingBlock} />
                {componentList}
            </div>;

            var disabled = null;
            if (this.state.saving){disabled = {disabled: true};}
            var key = 'pagebuilder_meta_form'

            var meta_state = {name: this.state.name, url:this.state.url, pagegroup:this.state.pagegroup,page_state:this.state.page_state, description:this.state.description}
            var page_meta_form = <div className="container">
                <a href='/pageList/'>See All Pages</a>
                <FormWithChildren key={key} defaults={meta_state} autoSetGlobalState={true} setGlobalState={this.setGlobalStateName} globalStateName={key}>
                    <TextInput name='name' label='Name' placeholder='Page Name' />
                    <TextInput name='description' label='Page Description' placeholder='Page Description' />
                    <TextInput name="url" label="URL" placeholder="/" />
                    <Select name="pagegroup" label="Page Group" optionsUrl={'/api/modelWebsite/pagegroup/'}
                        optionsUrlMap={{'text':'{pagegroup.name}', value:'{pagegroup.id}'}} defaultoption={this.props.page_group_id} />
                    <Json_Input name="page_state" label="Page State" placeholder="{}" />
                </FormWithChildren>

                <Button text={'save'} type={'success'} onClick={this.save} {...disabled} />

                <If logic={[['exists', this.props.page_id]]}>
                    <Button text={'Duplicate Page'} type={'info'} onClick={this.duplicate_page} {...disabled} />
                    <Button text={'delete'} type={'danger'} onClick={this.delete} deleteType={true} />
                </If>
                <If logic={[['0294d7d0-f9cf-457c-83d7-4632682934da',this.props.page_group_id]]}>
                    <Button text={'Export'} type={'outline-danger'} onClick={this.export} deleteType={true} />
                </If>
            </div>;

            var page_meta_form_sidebar = <Sidebar loaded={true} content={page_meta_form} openerPosition={'100px'}
                openerText={'Page Meta'} headerHeight={100} widthPercent={50} />;

            var content = <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.name}</title>
                <meta name = "description" content={this.state.description} />
            </Helmet>

                {page_meta_form_sidebar}
                <div className="row">
                    <div className="col-2">
                        <div style={{maxHeight:window_height+'px', overflow:'hidden auto'}}>{componentColumn}</div>
                    </div>
                    <div className="col-2">
                        <div style={{maxHeight:window_height+'px', overflow:'hidden auto'}}>
                            <h1>Edit Props</h1>
                            {componentPropsForm}
                        </div>
                    </div>
                    <div className="col-8">
                        <div style={{maxHeight:window_height+'px', overflow:'hidden auto'}}>
                            {display}
                        </div>
                    </div>
                </div>
            </div>;
        }

        return (
            <Wrapper loaded={this.state.loaded} saving={this.state.saving} saving_content={this.state.saving_content} content={content} />
        );
    }
}
export default PageBuilder;
