import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {CSSInput, TextInput} from 'library';

class TableHeader extends React.Component {
    static component_name = 'TableHeader';
    constructor(props) {
        super(props);

        this.config = {
            form_components: [
                <TextInput label={'data'} name={'data'} default='' />,
                <TextInput label={'Sort By'} name={'sort_by'} default='' />,
                <TextInput label={'Sort Name'} name={'sort_name'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.sort_by != '' && this.props.sort_name != '') {
            //Subscribe to sort_name
            window.cmState['subscribe_by_name'](this, this.props.sort_name);

            var current_sort = window.cmState.getGlobalState()[this.props.sort_name];
            var reverse_sort = '-' + this.props.sort_by;
            if (this.props.sort_by == current_sort) {
                window.cmState.setGlobalState(this.props.sort_name, reverse_sort);
            }
            else if (reverse_sort == current_sort) {
                window.cmState.setGlobalState(this.props.sort_name, this.props.sort_by);
            }
        }
    }

    render() {
        var style = this.props.style || {};
        console.log("Style", style)
        if (this.props.sort_name != '' && this.props.sort_name != undefined) {
                var current_sort = window.cmState.getGlobalState()[this.props.sort_name];
                var reverse_sort = '-' + this.props.sort_by;
                //style['cursor'] = 'pointer';
        }

        return (
            <th onClick={this.onClick} style={style}>
                {this.props.data || this.props.children}
            </th>
        );
    }
}

export default TableHeader;
