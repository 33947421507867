import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput} from 'library';

class MultiLineText extends React.Component {
    static component_name = 'MultiLineText';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'Text'} name={'text'} />,
            ],
        }
    }

    render() {
        return (
            <div>
            {this.props.text.split("\n").map((i,key) => {
                return <p key={key}>{i}</p>;
            })}
            </div>
        );
    }
}

export default MultiLineText;
