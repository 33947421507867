import $ from 'jquery';
import {ajaxWrapper} from 'functions';
import handle_error from './handle_error.js';
import clear_tokens from './clear_tokens.js';


function refresh_token(type, url, data, return_func){
    var refreshData = {};
    refreshData["csrfmiddlewaretoken"] = window.secretReactVars["csrfmiddlewaretoken"];

    refreshData['refresh'] = '';
    if (localStorage.getItem('refresh_token')) {
        refreshData['refresh'] = localStorage.getItem('refresh_token');
    }

    refreshData = JSON.stringify(refreshData);

    $.ajax({
        type: 'POST',
        url: '/users/token/refresh/',
        contentType: 'application/json',
        data: refreshData,
        statusCode: {
            401: function(xhr) {
                clear_tokens();
            }
        },
        success: function (value) {
            localStorage.setItem('token',value['access'])
            ajaxWrapper(type, url, data, return_func);
        },
        error: function(xhr, status, error) {
            clear_tokens();
        }
    });
    }


export default refresh_token;
