import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput, Select} from 'library';


var BAR_TYPES = [
  {'text': 'primary', value: 'primary'},
  {'text': 'secondary', value: 'secondary'},
  {'text': 'success', value: 'success'},
  {'text': 'danger', value: 'danger'},
  {'text': 'warning', value: 'warning'},
  {'text': 'info', value: 'info'},
  {'text': 'light', value: 'light'},
  {'text': 'dark', value: 'dark'},
];

class ProgressBar extends React.Component {
    static component_name = 'ProgressBar';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'text'} name={'text'} default={'Default Text'} />,
                <Select label={'type'} name={'type'} options={BAR_TYPES} />,
                <TextInput label={'min value'} name={'min'} default={'0'} />,
                <TextInput label={'max value'} name={'max'} default={'100'} />,
                <TextInput label={'current value'} name={'current'} default={'24'} />,
                <Select label={'striped'} name={'striped'} boolean={true} />,
                <Select label={'animated'} name={'animated'} boolean={true} />,
                <Select label={'pct as text?'} name={'pct_as_text'} boolean={true} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
        }
    }

    render() {

        var props = resolveVariables(this.props, window.cmState.getGlobalState(this));

        var animated = '';
        var striped = '';

        if (props.striped) {
            striped = 'progress-bar-striped';
        }

        if (props.animated) {
            animated = 'progress-bar-animated';
        }

        var text = props.text;
        var pct = (parseInt(props.current)/parseInt(props.max) * 100).toFixed(0)

        if (Number.isNaN(parseInt(props.current)/parseInt(props.max))) {
            pct = 0;
        }

        if (props.pct_as_text) {
            text = pct + '%';
        }

        return (
            <div class="progress" style={this.props.style || {}}>
              <div class={"progress-bar bg-" + props.type + " " + striped + " " + animated} style={{width: pct + '%'}} role="progressbar" aria-valuenow={props.current} aria-valuemin={props.min} aria-valuemax={props.max}>{text}</div>
            </div>
        );
    }
}

export default ProgressBar;
