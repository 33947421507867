import React, { Component } from 'react';

class LineBreak extends React.Component {
    static component_name = 'LineBreak';
    render() {
        return (
            <br />
        );
    }
}


export default LineBreak;
