import React, { Component } from 'react';
import {Button} from 'library';


class DuplicateComponent extends Component {
    constructor(props) {
        super(props);

        this.duplicate = this.duplicate.bind(this);
    }

    componentWithChildren(top_level, parent_index) {
        for (var index in this.props.components) {
            var component = this.props.components[index];
            if (component.parent == parent_index) {
                var classRef = component.class;
                delete component.children;
                delete component.props.children;

                var new_component = JSON.parse(JSON.stringify(component));
                new_component.class = classRef;
                var child_list = this.componentWithChildren([new_component], component['key']);
                //console.log("Child List", child_list)
                top_level.push(...child_list)
            }
        }
        return top_level
    }

    duplicate() {
        var component = this.props.selected_component;
        var classRef = component.class;
        delete component.children;
        delete component.props.children;

        var selected_component = JSON.parse(JSON.stringify(component));
        selected_component.class = classRef;

        var componentWithChildren = this.componentWithChildren([selected_component], component['key']);
        console.log("Component With Children", componentWithChildren);
        this.props.addBuildingBlock({'components':componentWithChildren}, selected_component.parent);
    }

    render() {
        return (<Button text={'Duplicate'} type={'primary'} onClick={this.duplicate} />);
    }
}

export default DuplicateComponent;
