import React, { Component } from 'react';

import {ajaxWrapper} from 'functions';
import {resolveVariables} from 'functions';
import {
    Checkbox, TextInput, Select, Json_Input, CSSInput
} from 'library';

class CheckGroup extends React.Component {
    static component_name = 'CheckGroup';

    constructor(props) {
        super(props);
        this.state = {options:[]}

        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'label'} name={'label'} />,
                <Select label={'required'} name={'required'} boolean={true} defaultoption={false} />,
                <Json_Input label={'options'} name={'options'} />,
                <TextInput label={'Options URL'} name={'optionsUrl'} />,
                <Json_Input label={'Options URL Map'} name={'optionsUrlMap'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
        }

        this.optionsCallback = this.optionsCallback.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.optionsUrl && this.props.optionsUrl != '') {
            ajaxWrapper("GET", this.props.optionsUrl, {}, this.optionsCallback.bind(this));
        }
    }

    optionsCallback(value) {
      console.log("Options Callback", value);
        var options = [];
        for (var index in value) {
            var textValue = value[index];
            var valueValue = value[index];

            if (this.props.optionsUrlMap) {
                var resolvedValue = resolveVariables(this.props.optionsUrlMap, textValue);
                textValue = resolvedValue['text']
                valueValue = resolvedValue['value']
            }
            options.push({'text':textValue,'value':valueValue});

        }
        console.log("Options",options);
        this.setState({options:options})
    }

    handleChange (e){
        var checkbox_value = Object.keys(e)[0];
        var selection = e[checkbox_value];

        var value = [];
        if (this.props.value && typeof(this.props.value) != 'undefined'){
            value = this.props.value;
        }

        var index = value.indexOf(checkbox_value);
        if (index == -1 && typeof(selection) != 'undefined') {
            value.push(selection);
        } else if (typeof(selection) == 'undefined') {
            value.splice(index, 1);
        }

        var newState = {};
        newState[this.props.name] = value;

        console.log("New State",newState);
        this.props.setFormState(newState);
    }

    render() {
        var value = [];
        if (this.props.value && typeof(this.props.value) != 'undefined'){
            value = this.props.value;
        }

    	var type = " btn-" + this.props.type;

        var layout = '';
        if (this.props.layout) {
            layout = 'form-group ' + this.props.layout;
        }

        var label = null;
        if (this.props.label && this.props.label != ''){
            label = <label>{this.props.label}</label>;
        }

    	var buttons = [];
        var css = {}

        var optionDict = this.props.options;
        if (!(this.props.options)){
            optionDict = this.state.options;
        }

        if (typeof(optionDict) == 'string'){
            try {
                var parsed_dict = JSON.parse(optionDict);
                optionDict = [];
                for (var index in parsed_dict) {
                    var textValue = parsed_dict[index];
                    var valueValue = parsed_dict[index];

                    if (this.props.optionsUrlMap) {
                        var resolvedValue = resolveVariables(this.props.optionsUrlMap, textValue);
                        textValue = resolvedValue['text'];
                        valueValue = resolvedValue['value'];
                    }
                    optionDict.push({'text':textValue,'value':valueValue});
                }
            }
            catch (e){
                console.log(e);
            }
        }


    	for (var index in optionDict) {
            var checked = false;
    	    if (value.indexOf(optionDict[index]['value']) > -1) {
    	        checked = true;
    	    }

	        buttons.push(
                <Checkbox name={this.props.name}
                    checked={checked}
                    label={optionDict[index]['text']}
                    value={optionDict[index]['value']}
                    setFormState={this.handleChange.bind(this)}
                    checkGroup={true}
                />
            );
    	}

        return (
            <div className={layout} style={{'display':'block'}}>
                {label}
                {buttons}
            </div>
        );
    }
}

export default CheckGroup;
