import refresh_token from './refresh_token.js';
import clear_tokens from './clear_tokens.js';


function handle_error(xhr, type, url, data, return_func, fail_func) {
    console.log("Ajax Failure");
    console.log(xhr);

    if (fail_func) {
        fail_func(xhr.responseText);
    }
    else if (xhr.status == 400) {
        refresh_token(type, url, data, return_func, clear_tokens);
    }
}


export default handle_error;
