import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {Json_Input, TextInput} from 'library';

class Hover extends Component {
    static component_name = 'Hover';
    constructor(props) {
        super(props);

        this.state = {hover: false}

        this.config = {
            form_components: [
                <TextInput label={'class'} name={'class'} />,
                <Json_Input label={'css'} name={'style'} />,
            ],
            can_have_children: true,
        }

        this.hover = this.hover.bind(this);
    }

    hover() {
        this.setState({hover: !this.state.hover})
    }

    render() {

        var to_render = this.props.children[0];
        if (this.state.hover) {
            to_render = this.props.children[1];
        }

        return (
            <div onMouseEnter={this.hover} onMouseLeave={this.hover} className={this.props.class} style={this.props.style}>
                {to_render}
            </div>
        )

    }
}

export default Hover;
