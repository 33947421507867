import React, { Component } from 'react';
import {resolveVariables} from 'functions';

import {
    Button
} from 'library';

class TextAutocomplete extends Component {
    static component_name = 'TextAutocomplete';

    constructor(props) {
        super(props);

        this.state = {
            key_position: 0
        };

        this.full_input_container = React.createRef();
        this.text_input = React.createRef();
        this.check_for_tab = this.check_for_tab.bind(this);
        this.check_for_blur = this.check_for_blur.bind(this);
        this.autocomplete_select = this.autocomplete_select.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.check_for_blur, false);
        document.addEventListener('keydown', this.check_for_tab, false);
    }
    componentWillUnmount(){
        document.removeEventListener('mousedown', this.check_for_blur, false);
        document.removeEventListener('keydown', this.check_for_tab, false);
    }

    check_for_blur(e) {
        if (this.full_input_container.current.contains(e.target)){
            return;
        }

        if (this.props.blur){
            this.props.blur(e);
        }
    }

    check_for_tab(e) {
        if (e.key === "Tab" && this.full_input_container.current.contains(document.activeElement)) {
            this.props.tab(e);
        }
    }

    autocomplete_select(e) {
        var value = e.currentTarget.getAttribute('num');

        var formState = {};
        formState[this.props.name] = value;
        this.props.setFormState(formState);
    }

    render() {
        var autocomplete_style = {
            border: "thin solid #bbb",
            position: "absolute",
            top: "42px",
            background: "white",
            left: "4px",
            borderRadius: "4px",
            boxShadow: "2px 2px 5px rgba(0,0,0,.2)",
            zIndex: '100',
            maxHeight: '500px',
            overflowY: 'auto',
            overflowX: 'hidden',
        }
        var button_style = {
            width: '100%',
            borderRadius: '0px',
            textAlign: 'left',
            padding: '2px 8px',
            fontSize: '14px',
        }


        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        var i = 0;
        var options = [];
        var optionsJSX = null;
        if ('options' in this.props){
            for (var key in this.props.options){
                var value = this.props.options[key];
                if (this.props.value && this.props.value != "" && value.toLowerCase().indexOf(this.props.value.toLowerCase()) > -1){
                    var type="light";
                    if (i == this.state.key_position){
                        type += ' active';
                    }

                    options.push(
                        <Button onClick={this.autocomplete_select} type={type} text={key} num={value} style={button_style} />
                    );
                }

                i += 1;
            }
        }
        if (options.length > 0){
            optionsJSX =
            <div style={autocomplete_style}>
                {options}
            </div>;
        }

        var label = null;
        if (this.props.label && this.props.label != ''){
            label = <label>{this.props.label}</label>;
        }

        return (
            <div style={{position:'relative'}} ref={this.full_input_container} >
                <div className={"form-group " + this.props.layout}>
                    {label}
                    <input type="text" className="form-control" name={this.props.name}
                        onChange={this.props.handleChange} value={this.props.value}
                        placeholder={this.props.placeholder} autoComplete="off" ref={this.text_input}
                    />
                </div>

                {optionsJSX}
            </div>
        )


    }
}

export default TextAutocomplete;
