import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {NumberInput, TextInput, CSSInput} from 'library';

class Paragraph extends React.Component {
    static component_name = 'Paragraph';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [

                <TextInput label={'text'} name={'text'} default={'Default Text'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
        }
    }

    render() {
        var text = this.props.text;
        if (typeof text == 'object') {
            text = JSON.stringify(text);
        }

        return (
            <p onClick={this.props.onClick} style={this.props.style || {}}>{text}</p>
        );
    }
}

export default Paragraph;
