
function setGlobalState(name, state) {
    console.log("Setting Global State", name, state);

    if (typeof state == 'object') {
        var new_state = window.cmState[name] || {};
        for (var index in state) {
            new_state[index] = state[index];
        }
        window.cmState[name] = new_state;
    }
    else {
        window.cmState[name] = state;
    }

    check_subscribers_to_update(name, state);
}


function check_subscribers_to_update(name, state){
    if (window.cmState.kwarg_subscribers){

        if (window.cmState.kwarg_subscribers[name]){
            var subscriber_list = window.cmState.kwarg_subscribers[name];
            update_subscribers(subscriber_list);
        }

        //Check if state update is more complex than a string value
        if (typeof state == 'object') {
            for (var key in state){
                //Use the variable format to compose a accurate name
                var deeper_name = name + '.' + key;

                if (window.cmState.kwarg_subscribers[deeper_name]){
                    var subscriber_list = window.cmState.kwarg_subscribers[deeper_name];
                    update_subscribers(subscriber_list);
                }
            }
        }
    }

    return true;
}


function update_subscribers(context){
    for (var index in context) {
        var component = context[index];
        if (component.refreshData) {
            component.refreshData();
        }
        else if (component.forceUpdate) {
            component.forceUpdate();
        }
    }
}

function subscribe(component, unresolved_string) {
    //Components must subscribe themselves to global state updates
    //This is accomplished by providing their own context, and the string
    //containing whichever variables they want to subcribe to
    if (!unresolved_string) {
        return false;
    }

    //Instantiate the subscription dictionary
    if (!window.cmState['kwarg_subscribers']) {
        window.cmState['kwarg_subscribers'] = {};
    }

    //Pull all varaibles out of submitted string
    var anything_inside_curly_brackets_regex = /\{([^{}]+)\}/g;
    var matches = [];
    if (typeof unresolved_string == 'string') {
        var matches = [...unresolved_string.matchAll(anything_inside_curly_brackets_regex)];
    }


    for (var i in matches) {
        var match = matches[i][1];

        //Reduce varaible down to two values because subscription only checks that deep
        if(match.indexOf('.') > -1) {
            var match_peices = match.split('.');
            match = match_peices[0] + '.' + match_peices[1];
        }
        subscribe_by_name(component, match);
    }
}

function subscribe_by_name(component, name){
    if (!window.cmState['kwarg_subscribers'][name]) {
        window.cmState['kwarg_subscribers'][name] = [];
    }

    if (window.cmState.kwarg_subscribers[name].indexOf(component) == -1) {
        window.cmState.kwarg_subscribers[name].push(component);
    }
}


function getGlobalState(component) {
    return window.cmState;
}

window.cmState['setGlobalState'] = setGlobalState;
window.cmState['getGlobalState'] = getGlobalState;
window.cmState['subscribe'] = subscribe;
window.cmState['subscribe_by_name'] = subscribe_by_name;

export default setGlobalState;
