import React, { Component } from 'react';
import {CSSInput, TextInput} from 'library';

class TableData extends React.Component {
    static component_name = 'TableData';
    constructor(props) {
        super(props);

        this.config = {
            form_components: [
                <TextInput label={'data'} name={'data'} default='' />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }
    }

    render() {

        return (
            <td onClick={this.props.onClick} style={this.props.style || {}}>
                {this.props.data || this.props.children}
            </td>
        );
    }
}

export default TableData;
