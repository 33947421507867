// CheckoutForm.js
import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import {ajaxWrapper, run_functions} from 'functions';

import StripeCard from './stripecard.js';
class StripeForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {saving: false};

        this.stripeCallback = this.stripeCallback.bind(this);
    }

    handleSubmit = (ev) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();

        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        this.props.stripe.createToken({name: this.props.email}).then(({token}) => {
        console.log("Stripe Ajax Request", {'token':token.id, 'description':this.props.description, price: this.props.price});
        ajaxWrapper("POST", this.props.url, {'token':token.id, 'description':this.props.description, price: this.props.price}, this.stripeCallback);
        });

        // However, this line of code will do the same thing:
        //
        // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

        // You can also use createSource to create Sources. See our Sources
        // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
        //
        // this.props.stripe.createSource({type: 'card', name: 'Jenny Rosen'});

        this.setState({saving: true});
    };

    stripeCallback(value) {
        console.log("Value", value)
        run_functions(this.props.functions);
    }

    render() {
        var button = <button className="btn btn-success">{this.props.submit || "Pay"}</button>;
        if (this.state.saving) {
            button = <button className="btn btn-success disabled" disabled={"disabled"} >{this.props.submit || "Pay"}</button>;
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <StripeCard />
                {button}
            </form>
        );
    }
}

export default injectStripe(StripeForm);
