import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {Json_Input, TextInput} from 'library';
import jsonLogic from 'json-logic-js';


function make_logic_text(logic, depth = 0) {
    if (logic) {
        if (typeof logic == 'object') {
            for (var index in logic) {
                if (index == '==') {
                    return make_logic_text(logic[index][0], depth + 1) + ' equal to ' + make_logic_text(logic[index][1], depth + 1)
                }
                else if (index == '>') {
                    return make_logic_text(logic[index][0], depth + 1) + ' greater than ' + make_logic_text(logic[index][1], depth + 1)
                }
                else if (index == '>=') {
                    return make_logic_text(logic[index][0], depth + 1) + ' greater than or equal to ' + make_logic_text(logic[index][1], depth + 1)
                }
                else if (index == '<') {
                    return make_logic_text(logic[index][0], depth + 1) + ' less than ' + make_logic_text(logic[index][1], depth + 1)
                }
                else if (index == '<=') {
                    return make_logic_text(logic[index][0], depth + 1) + ' less than or equal to' + make_logic_text(logic[index][1], depth + 1)
                }
                else if (index == 'and') {
                    var return_values = []
                    for (var i in logic[index]) {
                        return_values.push(make_logic_text(logic[index][i], depth + 1))
                    }
                    console.log("Return Values", return_values, logic)

                    if (depth > 0) {
                        return '(' + return_values.join(' and ') + ')'
                    }
                    else {
                        return return_values.join(' and ')
                    }
                }
                else if (index == 'or') {
                    var return_values = []
                    for (var i in logic[index]) {
                        return_values.push(make_logic_text(logic[index][i], depth + 1))
                    }

                    if (depth > 0) {
                        return '(' + return_values.join(' or ') + ')'
                    }
                    else {
                        return return_values.join(' or ')
                    }

                }
            }
        }
        else {
            return logic
        }
    }
    else {
        return null;
    }

}

class LogicDisplay extends React.Component {
    static component_name = 'LogicDisplay';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <Json_Input label={'Logic'} name={'logic'} />,
                <TextInput label={'Json String'} name={'json_string'} />,
            ],
        }
    }

    render() {

        var logic = this.props.logic;
        if (logic) {
            try {
                if (this.props.json_string) {
                    var logic = this.props.json_string;
                }
            }
            catch (e) {
                var logic = {}
            }
            var logic_text = make_logic_text(logic)

            var result_text = 'False';
            if (jsonLogic.apply(logic)) {
                result_text = 'True'
            }

            return (
                <div>
                <p>Is {logic_text}?</p>
                </div>
            );
        }
        else {
            return (
                <div>
                <p>Is ?</p>
                </div>
            )
        }

        console.log("Logic", logic)

    }
}

export default LogicDisplay;
