import React, { Component } from 'react';
import {Button, Link} from 'library';
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';


const ItemTypes = {
  Draggable: 'Draggable',
}

class ComponentList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var componentList = [];
        for (var index in this.props.component_tree) {
            try {
                var component = this.props.component_tree[index];
                componentList.push(
                    <ComponentInstance key={component['key']} index={component['key']} type={component['type']} class={component['class']}
                        name={component.props['display_name']} not_editable={component.props['not_editable']} component={component} component_key_map={this.props.component_key_map}
                        setComponent={this.props.setComponent} selected_component={this.props.selected_component} change_parent={this.props.change_parent} >

                        <ComponentList key={component['key']} component_tree={component.children} component_key_map={this.props.component_key_map}  setComponent={this.props.setComponent}
                            selected_component={this.props.selected_component} change_parent={this.props.change_parent} />

                    </ComponentInstance>
                );
            }
            catch (error) {
                console.log("Error likely due to missing or not imported component.", error);
            }
        }

        return (
            <DndProvider backend={HTML5Backend}>{componentList}</DndProvider>
        );
    }
}


class ComponentInstance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            child_toggle: '+'
        }

        this.set_component = this.set_component.bind(this);
        this.toggle_children = this.toggle_children.bind(this);
        this.can_drop = this.can_drop.bind(this);
    }

    set_component() {
        this.props.setComponent(this.props.index);
    }

    toggle_children() {
        var child_toggle = this.state.child_toggle;
        if (this.state.child_toggle == '-'){
            child_toggle = '+';
        } else {
            child_toggle = '-';
        }

        this.setState({child_toggle: child_toggle});
    }

    can_drop(drag, drop){
        var found = (drag == drop);
        var key = drop;

        while(!(found) && key in this.props.component_key_map){
            key = this.props.component_key_map[key];
            found = (key == drag);
        }

        return !(found);
    }


    render() {
        var children_style = {marginLeft: "15px", overflow: 'hidden'};
        if (this.state.child_toggle == '+'){
            children_style['height'] = '0px';
        }

        var type = 'outline-dark';
        var name = this.props.name;
        if (!this.props.name){
            name = this.props.type;
        }

        if (this.props.selected_component && this.props.selected_component['key'] == this.props.index) {
            type = 'primary'
        }

        var children = null;
        var toggle_button = null;
        if ('children' in this.props.component && this.props.component['children'].length > 0){
            if (('not_editable' in this.props) && this.props.not_editable){
                toggle_button = <Link text={'>'} type={'outline-disabled'} target={"_blank"} className={'btn btn-outline-info'}
                    href={"/pagebuilder/random_string/" + this.props.component.building_block_id + "/"} />;
            }
            else {
                toggle_button = <Button onClick={this.toggle_children} text={this.state.child_toggle} type={'outline-info'} />;
                children = <div style={children_style}>{this.props.children}</div>;
            }
        }

        return (
            <div>
                <DropZone drop_key={this.props.index} change_parent={this.props.change_parent} can_drop={this.can_drop} >
                    <Draggable drag_key={this.props.index}>
                        <Button onClick={this.set_component} text={name + ' : ' + this.props.index} type={type}/>
                        {toggle_button}
                    </Draggable>
                </DropZone>
                {children}
            </div>
        )
    }
}

function Draggable(props) {
    const [{isDragging}, drag] = useDrag({
        item: { type: ItemTypes.Draggable, drag_key: props.drag_key },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                fontSize: 25,
                fontWeight: 'bold',
                cursor: 'move',
                position: 'relative',
            }}
            >
            {props.children}
        </div>
    );
}

function DropZone(props) {
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: ItemTypes.Draggable,
        canDrop: (dropped) => props.can_drop(dropped.drag_key, props.drop_key),
		drop: (dropped) => props.change_parent(dropped.drag_key, props.drop_key),
		collect: monitor => ({
			isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
		}),
	})

  return (
    <div ref={drop} style={{position: 'relative'}} >
        {props.children}
        {isOver && canDrop && (
        <div
          style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
            opacity: 0.5,
            backgroundColor: 'yellow',
            width:'100%',
            height:'100%'
          }}
        />
    )}
    </div>
  )
}




export default ComponentList;
