import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {FormWithChildren, TextInput, SelectAutocomplete, Button, Select, TextAutocomplete} from 'library';

class CSSInput extends Component {
    static component_name = 'CSSInput';
    constructor(props) {
        super(props);

        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'default'} name={'default'} />,
                <TextInput label={'placeholder'} name={'placeholder'} />,
                <TextInput label={'label'} name={'label'} />,
                <Select label={'inline'} name={'inline'} boolean={true} />,
            ],
        }

        var date = Date.now();
        this.state = {
            timestamp: date,
            show_delete: false
        };

        this.setGlobalState = this.setGlobalState.bind(this);
        this.addCSSField = this.addCSSField.bind(this);
        this.removeCSSField = this.removeCSSField.bind(this);
        this.mouse_enter = this.mouse_enter.bind(this);
        this.mouse_leave = this.mouse_leave.bind(this);
    }

    componentDidMount() {
        this.css_options = [];
        var css_props = Object.keys(CSS_PROPS);

        for (var i in css_props){
            var text = css_props[i];
            var pieces = text.split('-');
            var value = pieces.shift();
            for (var i in pieces){
                value += pieces[i][0].toUpperCase();
                value += pieces[i].slice(1);
            }

            if (this.props.inline){
                this.css_options.push({text:text, value:value});
            }
            else {
                this.css_options.push({text:text, value:text});
            }
        }
    }

    componentDidUpdate(prevProps) {
        var value = this.props.value;

        if (typeof(value) == 'string') {
            try {
                value = JSON.parse(value);
                var formState = {};
                formState[this.props.name] = value;
                this.props.setFormState(formState);
            }
            catch (e){console.log(e);}
        }
    }

    setGlobalState(name, state) {
        var newState = {};
        for (var index in state) {
            if (index.indexOf('name_') > -1 && !(name[index] in newState)){
                newState[state[index]] = '';
            }
            if (index.indexOf('value_') > -1) {
                var i = index.split('_')[1]
                var name = state['name_' + i]
                newState[name] = state[index];
            }
        }

        var formState = {};
        formState[this.props.name] = newState;
        console.log("Set Form State", formState);
        this.props.setFormState(formState);
    }

    mouse_enter() {
        this.setState({show_delete: true});
    }
    mouse_leave() {
        this.setState({show_delete: false});
    }


    addCSSField() {
        var value = {};
        if (this.props.value){
            value = Object.assign({},this.props.value);
        }

        value[""] = "";

        var formState = {};
        formState[this.props.name] = value;
        this.props.setFormState(formState);
    }

    removeCSSField(e) {
        var name = e.currentTarget.getAttribute('num');
        var value = this.props.value;

        delete value[name];
        var formState = {};
        formState[this.props.name] = value;
        this.props.setFormState(formState);

        var date = Date.now();
        this.setState({timestamp: date});
    }

    render() {
        var form = null;
        var components = [];
        var componentProps = [];
        var defaults = {}
        var i = 0;

        var values = this.props.value;

        if (values && typeof(values) != 'string' && typeof(values) != 'undefined'){
            console.log("Values", values);
            for (var index in values) {
                var text_options = [];
                if (index in CSS_PROPS){
                    for (var choice in CSS_PROPS[index]){
                        text_options.push({'text': CSS_PROPS[index][choice], 'value': CSS_PROPS[index][choice]});
                    }
                }

                var button = undefined;
                if (this.state.show_delete){
                    //components.push(<Button type={'danger'} text={'X'} onClick={this.removeCSSField} num={index} />);
                }
                components.push(<SelectAutocomplete select={true} name={'name_' + i} layout={'col-6 no-padding'} style={{width:'100%'}} options={this.css_options} />);
                components.push(<SelectAutocomplete name={'value_' + i} layout={'col-6 no-padding'} style={{width:'100%'}} options={text_options} tab={this.addCSSField} />);

                defaults['name_' + i] = index;
                defaults['value_' + i] = values[index];
                i += 1
            }

            form = <FormWithChildren key={this.state.timestamp} className={'row'} layout={'form-inline'} defaults={defaults}
                autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'form'} full_state={true} >
                {components}
            </FormWithChildren>;
        }

        return (
            <div className={"form-group "} onMouseEnter={this.mouse_enter} onMouseLeave={this.mouse_leave}>
                {this.props.label}
                <br />
                {form}
                <br />
                <Button type={'primary'} text={'Add CSS Field'} onClick={this.addCSSField} />
            </div>
        )
    }
}


const CSS_PROPS = {
    "align-content":['stretch','center','flex-start','flex-end','baseline','initial','inherit'],
    "align-items":[],
    "align-self":[],
    "all":[],
    "animation":[],
    "animation-delay":[],
    "animation-direction":[],
    "animation-duration":[],
    "animation-fill-mode":[],
    "animation-iteration-count":[],
    "animation-name":[],
    "animation-play-state":[],
    "animation-timing-function":[],
    "backface-visibility":[],
    "background":[],
    "background-attachment":[],
    "background-blend-mode":[],
    "background-clip":[],
    "background-color":[],
    "background-image":[],
    "background-origin":[],
    "background-position":['left', 'right', 'center', 'bottom', 'top'],
    "background-repeat":[],
    "background-size":['auto','length','cover','contain','initial','inherit'],
    "border":[],
    "border-bottom":[],
    "border-bottom-color":[],
    "border-bottom-left-radius":[],
    "border-bottom-right-radius":[],
    "border-bottom-style":[],
    "border-bottom-width":[],
    "border-collapse":[],
    "border-color":[],
    "border-image":[],
    "border-image-outset":[],
    "border-image-repeat":[],
    "border-image-slice":[],
    "border-image-source":[],
    "border-image-width":[],
    "border-left":[],
    "border-left-color":[],
    "border-left-style":[],
    "border-left-width":[],
    "border-radius":[],
    "border-right":[],
    "border-right-color":[],
    "border-right-style":[],
    "border-right-width":[],
    "border-spacing":[],
    "border-style":[],
    "border-top":[],
    "border-top-color":[],
    "border-top-left-radius":[],
    "border-top-right-radius":[],
    "border-top-style":[],
    "border-top-width":[],
    "border-width":[],
    "bottom":[],
    "box-decoration-break":[],
    "box-shadow":[],
    "box-sizing":[],
    "caption-side":[],
    "caret-color":[],
    "@charset":[],
    "clear":[],
    "clip":[],
    "color":[],
    "column-count":[],
    "column-fill":[],
    "column-gap":[],
    "column-rule":[],
    "column-rule-color":[],
    "column-rule-style":[],
    "column-rule-width":[],
    "column-span":[],
    "column-width":[],
    "columns":[],
    "content":[],
    "counter-increment":[],
    "counter-reset":[],
    "cursor":[],
    "direction":[],
    "display":['inline', 'block', 'inline-block', 'flex', 'table', 'table-cell', 'list-item', 'none'],
    "empty-cells":[],
    "filter":[],
    "flex":[],
    "flex-basis":[],
    "flex-direction":[],
    "flex-flow":[],
    "flex-grow":[],
    "flex-shrink":[],
    "flex-wrap":[],
    "float":['none','left','right','initial','inherit'],
    "font":[],
    "@font-face":[],
    "font-family":[],
    "font-kerning":[],
    "font-size":[],
    "font-size-adjust":[],
    "font-stretch":[],
    "font-style":[],
    "font-variant":[],
    "font-weight":['normal','bold','bolder','lighter','number','initial','inherit'],
    "grid":[],
    "grid-area":[],
    "grid-auto-columns":[],
    "grid-auto-flow":[],
    "grid-auto-rows":[],
    "grid-column":[],
    "grid-column-end":[],
    "grid-column-gap":[],
    "grid-column-start":[],
    "grid-gap":[],
    "grid-row":[],
    "grid-row-end":[],
    "grid-row-gap":[],
    "grid-row-start":[],
    "grid-template":[],
    "grid-template-areas":[],
    "grid-template-columns":[],
    "grid-template-rows":[],
    "hanging-punctuation":[],
    "height":[],
    "hyphens":[],
    "@import":[],
    "isolation":[],
    "justify-content":['flex-start','flex-end','center','space-between','space-around','initial','inherit'],
    "@keyframes":[],
    "left":[],
    "letter-spacing":[],
    "line-height":[],
    "list-style":[],
    "list-style-image":[],
    "list-style-position":[],
    "list-style-type":[],
    "margin":[],
    "margin-bottom":[],
    "margin-left":[],
    "margin-right":[],
    "margin-top":[],
    "max-height":[],
    "max-width":[],
    "@media":[],
    "min-height":[],
    "min-width":[],
    "mix-blend-mode":[],
    "object-fit":[],
    "object-position":[],
    "opacity":[],
    "order":[],
    "outline":[],
    "outline-color":[],
    "outline-offset":[],
    "outline-style":[],
    "outline-width":[],
    "overflow":['visible','hidden','scroll','auto','initial','inherit'],
    "overflow-x":[],
    "overflow-y":[],
    "padding":[],
    "padding-bottom":[],
    "padding-left":[],
    "padding-right":[],
    "padding-top":[],
    "page-break-after":[],
    "page-break-before":[],
    "page-break-inside":[],
    "perspective":[],
    "perspective-origin":[],
    "pointer-events":[],
    "position":['static','absolute','fixed','relative','sticky','initial','inherit'],
    "quotes":[],
    "resize":[],
    "right":[],
    "scroll-behavior":[],
    "tab-size":[],
    "table-layout":[],
    "text-align":['left','right','center','justify','initial','inherit'],
    "text-align-last":[],
    "text-decoration":[],
    "text-decoration-color":[],
    "text-decoration-line":[],
    "text-decoration-style":[],
    "text-indent":[],
    "text-justify":[],
    "text-overflow":[],
    "text-shadow":[],
    "text-transform":[],
    "top":[],
    "transform":[],
    "transform-origin":[],
    "transform-style":[],
    "transition":[],
    "transition-delay":[],
    "transition-duration":[],
    "transition-property":[],
    "transition-timing-function":[],
    "unicode-bidi":[],
    "direction":[],
    "user-select":[],
    "vertical-align":[],
    "visibility":[],
    "white-space":['normal','nowrap','pre','pre-line','pre-wrap','initial','inherit'],
    "width":[],
    "word-break":[],
    "word-spacing":[],
    "word-wrap":[],
    "writing-mode":[],
    "z-index":[],
}


export default CSSInput;
