import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput, NumberInput} from 'library';

class Decimal extends React.Component {
    static component_name = 'Decimal';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'input'} name={'input'} default={'0'} />,
                <TextInput label={'class'} name={'className'} default={''} />,
                <NumberInput label={'places'} name={'places'} default={'2'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
        }
    }

    render() {
        var float = parseFloat(this.props.input)
        var decimal = parseFloat(Math.round(float * 100) / 100).toFixed(this.props.places);

        return (
            <span className={this.props.className} style={this.props.style || {}}>{decimal}</span>
        );
    }
}

export default Decimal;
