import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput, Select} from 'library';

class NumberInput extends Component {
    static component_name = 'NumberInput';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'label'} name={'label'} />,
                <TextInput label={'placeholder'} name={'placeholder'} />,
                <TextInput label={'default'} name={'default'} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'label css'} name={'label_style'} default={{}} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
                <Select label={'required'} name={'required'} boolean={true} defaultoption={false} />,
            ],
        }

        this.focus = this.focus.bind(this);
    }

    focus(event){
        try {
            event.target.setSelectionRange(0, event.target.value.length);
        }
        catch (e){
            event.target.select();
        }
    }

    render() {
        var value = this.props.value;
        if (!(value)) {
            value = this.props.default;
        }

        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        if (this.props.label) {
          var label = <label style={this.props.label_style || {}} >{this.props.label}</label>
        }

        return (
              <div className={"form-group " + this.props.className} style={this.props.style || {}}>
                {label}
                <input type="number" className="form-control" name={this.props.name}
                    onChange={this.props.handleChange} value={value}
                    placeholder={this.props.placeholder}
                    onKeyPress={this.props.handleKeyPress}
                    onBlur={this.props.onBlur}
                    onClick={this.focus}
                />
              </div>
        );
    }
}

export default NumberInput;
