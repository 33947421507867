import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';

class Alert extends React.Component {
    static component_name = 'Alert';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'text'} name={'text'} default='' />,
                <TextInput label={'type'} name={'type'} default='danger' />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }
    }

    render() {
        var type = 'primary';
        if (this.props.type) {
            type = this.props.type;
        }

        var text = unescape(this.props.text);
        if (!(text) || typeof(text) == 'undefined' || text == 'undefined'){
            text = null;
        }

        var style = {maxHeight: '400px', overflowY:'auto', overflowX:'hidden'};
        if (this.props.style){
            style = Object.assign({}, style, this.props.style);
        }

        return (
            <div className={'alert alert-' + type} role="alert" style={style}>
                {text}
                {this.props.children}
            </div>
        );
    }
}

export default Alert;
