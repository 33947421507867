import React, {Component} from 'react';
import {ajaxWrapper} from 'functions';
import {Button, EmptyModal, PageBreak, Header, Break} from 'library';
import ComponentNameLookup from './componentNameLookup.js';

var ComponentsByCategory = {
    'Display':
    ['Alert','Card','CardWithChildren','EmojiSlider','Header','HTMLInputDisplay',
    'Video', 'Icon','Icons','Image', 'Embed', 'MultiImageViewer','ListItem','MultiLineText','PageBreak','Paragraph',
    'Progress','Small','Span','Stars','Table','UnorderedList','Wrapper',
    'TableRow','TableData','TableHeader', 'Date', 'MultiLineText', 'HTMLInputDisplay',
    'LogicDisplay', 'HTMLDisplay', 'DateFormat', 'ProgressBar', 'LineChart', 'Decimal'],

    'Form':
    ['LogInForm','PasswordReset','PasswordResetRequest','SignUpForm','BooleanInput',
    'Button','ButtonGroup','Checkbox','CheckGroup','Checklist','ColorPicker',
    'CSSInput','DateTimePicker','EmojiSelect','FileInput','FormWithChildren',
    'Function_Input','GoogleAddress','HTMLInput','ImageInput','Json_Input','NumberInput',
    'PasswordInput','RadioButton','Select','StarInput','TextArea','TextAutocomplete',
    'TextInput', 'StripeMain', 'StarInput', 'HTMLInput', 'LogicInput', 'ListInput'],

    'Functional':
    ['Accordion','If','Instance','LineBreak','Link',
    'ListWithChildren','ListTable','MapEmbed','Modal',
    'Navbar','NavLink','ReactRender','Sidebar', 'Script',
    'Hover', 'BrowserType','DisplaySize', 'CopyToClipboard',
    'Math', 'Carousel', 'SetGlobalState', 'SetDate'],

    'Layout':
    ['Break','Container','ContainerFluid','Div','EmptyModal','Section'],
}

var ComponentDictList = []
for (var key in ComponentNameLookup) {
    ComponentDictList.push(key);
}
console.log("Component Dict List", ComponentDictList)
ComponentDictList.sort()

class AddBuildingBlock extends Component {
    constructor(props) {
        super(props);

        this.setBlock = this.setBlock.bind(this);
    }

    setBlock() {
        this.props.addBuildingBlock(this.props.building_block, this.props.parent_index);
    }

    render() {
        return (
            <div>
                <Button onClick={this.setBlock} text={this.props.building_block.name} type={'outline-primary'}/>
            </div>
        );
    }
}

class AddComponent extends Component {
  constructor(props) {
    super(props);
    this.setComponent = this.setComponent.bind(this);
  }

  setComponent() {
    this.props.addComponent(this.props.name)
  }

  render() {
    var type = 'outline-primary';

    return (
      <div>
      <Button onClick={this.setComponent} text={this.props.name.component_name} type={type}/>
      </div>
    )
  }
}


class AddChildComponent extends Component {
      constructor(props) {
        super(props);
        this.state = {adding: false, selectedList: null}

        this.addComponent = this.addComponent.bind(this);
        this.addingComponent = this.addingComponent.bind(this);
      }

      addingComponent() {
        this.setState({adding: !this.state.adding})
      }

    addComponent(componentName) {
        this.props.addComponent(componentName, this.props.parentIndex)
    }

  render() {
    var addable_components = [];

    if (this.state.selectedList == 'Building Blocks') {
        addable_components.push(<Header size={3} text='Building Blocks' />)
        addable_components.push(<Button onClick={() => this.setState({selectedList:null})} text={'Go Back'} type={'outline-primary'} />)

        for (var index in this.props.building_blocks) {
            var building_block = this.props.building_blocks[index];
            addable_components.push(<AddBuildingBlock building_block={building_block} type={'building_blocks'}
                parent_index={this.props.parentIndex} addBuildingBlock={this.props.addBuildingBlock} />);
        }
    }

    else if (this.state.selectedList == 'Project Components') {

        addable_components.push(<Header size={3} text='Project Components' />)
        addable_components.push(<Button onClick={() => this.setState({selectedList:null})} text={'Go Back'} type={'outline-primary'} />)

        for (var index in this.props.project_components) {
            var building_block = this.props.project_components[index];
            addable_components.push(<AddBuildingBlock building_block={building_block} type={'project_components'}
                parent_index={this.props.parentIndex} addBuildingBlock={this.props.addBuildingBlock} />);
        }
    }

    else if (this.state.selectedList) {

        addable_components.push(<Header size={3} text={this.state.selectedList + ' Components'} />)
        addable_components.push(<Button onClick={() => this.setState({selectedList:null})} text={'Go Back'} type={'outline-primary'} />)

        for (var index in ComponentDictList) {
            var key = ComponentDictList[index]
            if (ComponentsByCategory[this.state.selectedList].indexOf(key) > -1) {
                var component = ComponentNameLookup[key];
                addable_components.push(<AddComponent name={component} addComponent={this.addComponent} />);
            }

        }

    }

    else {
        addable_components.push(<Button onClick={() => this.setState({selectedList:'Display'})} text={'Display Components'} type={'outline-primary'} />)
        addable_components.push(<Break />)
        addable_components.push(<Button onClick={() => this.setState({selectedList:'Form'})} text={'Form Components'} type={'outline-primary'} />)
        addable_components.push(<Break />)
        addable_components.push(<Button onClick={() => this.setState({selectedList:'Functional'})} text={'Functional Components'} type={'outline-primary'} />)
        addable_components.push(<Break />)
        addable_components.push(<Button onClick={() => this.setState({selectedList:'Layout'})} text={'Layout Components'} type={'outline-primary'} />)
        addable_components.push(<Break />)
        addable_components.push(<Button onClick={() => this.setState({selectedList:'Building Blocks'})} text={'Building Blocks'} type={'outline-primary'} />)
        addable_components.push(<Break />)
        addable_components.push(<Button onClick={() => this.setState({selectedList:'Project Components'})} text={'Project Components'} type={'outline-primary'} />)
    }

    return (
      <div>
            <Button type={'primary'} text={this.props.label} onClick={this.addingComponent} />
            <EmptyModal show={this.state.adding} onHide={this.addingComponent}>
                <div style={{maxHeight:'800px', overflowY:'scroll', padding:'20px'}}>
                    {addable_components}
                </div>
            </EmptyModal>
      </div>
    )
  }
}

export default AddChildComponent;
