import React, { Component } from 'react';
import {TextInput} from 'library';
import Script from 'react-load-script'

class ScriptComponent extends React.Component {
    static component_name = 'Script';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'src'} name={'src'} />,
            ],
            can_have_children: false,
        }
    }

    render() {
        return (
            <Script url={this.props.src}></Script>
        );
    }
}


export default ScriptComponent;
